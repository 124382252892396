import axios from "axios"
import { API_BASE_URL } from "../config/config";

interface loginParams {
  email: string;
  password: string;
}

export const logInService = async (params: loginParams) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/users/login`,
      method: 'POST',
      data: params
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const checkLocalToken = async () => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/user/check-credentials`,
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}
