import { useState } from "react";
import AppRouter from "./Router/AppRouter";
import { MyGlobalContext } from "./context/AppContext";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import './interceptor';

function App() {
  const [title, setTitle] = useState<string>('Inicio')
  const [globalLoading, setGlobalLoading] = useState<boolean>(false);

  return (
    <ThemeProvider theme={ theme }>
      <MyGlobalContext.Provider value={{
        title, setTitle,
        globalLoading, setGlobalLoading
      }} >
        <AppRouter/>
      </MyGlobalContext.Provider>
    </ThemeProvider>
  );
}

export default App;
