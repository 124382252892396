import { Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import { useGlobalContext } from '../../../context/AppContext';
import { completeSsoLogout } from '../../../config/config';

type Props = {
  setNavOpen: Function;
};

const DashboardNavbar: React.FC<Props> = ({ setNavOpen }) => {
  const { title } = useGlobalContext();

  const onLogOut = () => {
    localStorage.clear();
    window.location.href = completeSsoLogout;
  }

  return (
    <AppBar elevation={0}>
      <Toolbar>
        <IconButton
          style={{ color: '#fff' }}
          onClick={() => setNavOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="h6">
          { title }
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Fragment>
          <IconButton
            style={{ color: '#fff' }}
            onClick={onLogOut}
          >
            <ExitToAppIcon />
          </IconButton>
        </Fragment>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
