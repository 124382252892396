import { makeStyles } from '@material-ui/core';
import { primaryColor } from '../../../../theme/config';

export const dashboarStyless = makeStyles(() => ({
  normalLink: {
    color: primaryColor,
    letterSpacing: 0,
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start',
    '& svg': { marginRight: '1rem' }
  },
  activeLink: {
    color: '#3f51b5',
    letterSpacing: 0,
    background: '#ebebeb',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start',
    '& svg': { marginRight: '1rem' }
  },
  headerSidebar: {
    backgroundColor: primaryColor,
  },
  boxSidebar: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '200px',
  }
}));

export const dashboarScreenStyless = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: '80vh'
  },
}));