import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Grid from '@material-ui/core/Grid';
import SimpleCard from '../../Cards/SimpleCard';
import { getMySitios, ListSite } from '../../../services/sitiosService';

const SitiosIndex = () => {

    const [ cookies ] = useCookies(['token']);
    const { token } = cookies;

    const [stateListEstudios, setStateListEstudios] = useState<ListSite[]>([]);

    useEffect(() => {

        const jwt_decode = JSON.parse(atob(token.split('.')[1]));
        let {user} = jwt_decode;
             
        getMySitios(user.useremail).then(resp => { 
            setStateListEstudios(resp.arrayObject);
        });
    }, [token])

    return (
        <>
        <Grid container spacing={3}>
            {stateListEstudios.map((item) => {
                return (
                    <Grid item lg={3} sm={6} xl={3} xs={12} key={item.SITE_URL}>
                        <SimpleCard
                            title={ item.SITE_NAME }
                            desciption={ item.SITE_URL}
                            toHref={ item.SITE_URL }
                        />
                    </Grid>
                )
            })}       
        </Grid>
        </>
    )
}

export default SitiosIndex
