import { useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { completeSsoRedirect } from '../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkLocalToken } from '../services/loginService';
import './styless.css';

const LoginRequireScreen = () => {
  const [ cookies ] = useCookies(['token', 'rol']);
  //const { token, rol } = cookies;
  const { token } = cookies;
  let rol:any = null;


  const verifyToken = useCallback(async () => {
    localStorage.setItem('rol', rol);
    const data = await checkLocalToken();
    if(data.ok){
      window.location.href = "/";
    }

  },[rol])

  useEffect(() => {
    if(token){
      verifyToken();
    }else{
      localStorage.clear();
      window.location.href = completeSsoRedirect;
    }
  }, [ token, verifyToken ])

  return (
    <div className="unauthorized-content">
      <CircularProgress />
    </div>
  )
}

export default LoginRequireScreen
