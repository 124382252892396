import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
});

type Props = {
  title: string,
  desciption: string,
  toHref: string
}

const SimpleCard: React.FC<Props> = ({ title, desciption, toHref }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          { title }
        </Typography>
        <Typography variant="body2" component="p">
          { desciption }
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          target="_blank"
          href={toHref}
          size="small"
          variant="contained"
          color="primary"
        >
          Ingresar
        </Button>
      </CardActions>
    </Card>
  );
}

export default SimpleCard;