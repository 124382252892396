import { createContext, useContext } from "react"

export type GlobalContent = {
  title: string
  setTitle:(c: string) => void,
  globalLoading: boolean,
  setGlobalLoading:(c: boolean) => void
}

export const MyGlobalContext = createContext<GlobalContent>({
  title: '', // set a default value
  setTitle: () => {},
  globalLoading: false,
  setGlobalLoading: () => {},
})

export const useGlobalContext = () => useContext(MyGlobalContext)