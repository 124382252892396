import axios from "axios"
import { API_BASE_URL } from "../config/config";

export interface ListSite {
  SITE_URL : string,
  SITE_NAME : string
};


export const getMySitios = async (email : string) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/sitios/list-sitios/${email}`,
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
};
