import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Button, ListItem } from '@material-ui/core';
import { dashboarStyless } from './makeStyles/dashboardStyless';

type Props = {
  href: string;
  icon: any;
  title: string;
  showMenu: Function;
};

const NavItem: React.FC<Props> = ({ href, icon: MyIcon, title, showMenu }) => {
  const { pathname } = useLocation();
  const { normalLink, activeLink } = dashboarStyless();
  const selectedStyleLink = pathname !== href ? normalLink : activeLink;

  return (
    <ListItem disableGutters>
      <Button
        to={href}
        className={selectedStyleLink}
        onClick={() => showMenu(false)}
        component={RouterLink}
      >
        <MyIcon size="20"/>
        <span>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
