import svgIcon from '../assets/undraw_page_not_found_su7k.svg';
import { completeSsoRedirect } from '../config/config';
import './styless.css';

export const UnauthorizedScreen = () => {

  const onRedirect = () => {
    localStorage.clear();
    window.location.href = completeSsoRedirect;
  }

  return (
    <div className="unauthorized-content">
      <img src={ svgIcon } alt="404" className="unauthorized-image" />
      <h2>Contenido no disponible para usted.</h2>
      <a href="#/" onClick={onRedirect} style={{ textAlign: 'center', textDecoration: 'none' }}>Ir al inicio de sesión</a>
    </div>
  )
}
