const site = window.location.origin;
const isProductionMode = process.env.NODE_ENV === 'production';

export const webSSO = isProductionMode
  ? 'https://sso.cadem.cl'
  : 'http://localhost:4500';

export const API_BASE_URL = isProductionMode
  ? 'https://backend-cadem-admin-xbstdiqb2a-ue.a.run.app' // Produccion Cloud Run
  : 'http://localhost:8080'; // Desarrollo

export const API_SSO_URL = isProductionMode
  ? 'https://backend-sso-xbstdiqb2a-ue.a.run.app' // Produccion Cloud Run
  : 'http://localhost:8081'; // Desarrollo

export const completeSsoRedirect = `${webSSO}/login?site=${utf8_to_b64(site)}`;
export const completeSsoLogout = `${webSSO}/logout?site=${utf8_to_b64(site)}`;

function utf8_to_b64(str: string ) {
  return window.btoa(unescape(encodeURIComponent( str )));
}
