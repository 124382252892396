import Info from '../Components/Pages/Info/Info';
import SitiosIndex from '../Components/views/Sitios';

export const rutesList = [
  {
    path : '/sitios',
    cp: SitiosIndex,
    exact: true,
    roles: [null]
  },
  {
    path: '/info',
    cp: Info,
    exact: true,
    roles: [null]
  },
]