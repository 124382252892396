import { useEffect, useRef } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useGlobalContext } from '../../../context/AppContext';

const Info: React.FC<{}> = () => {
  const { setTitle } = useGlobalContext();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) setTitle('Acceso no permitido');
    return () => {isMounted.current = false}
  }, [setTitle])

  return (
    <Card style={{ width: '100%' }} variant="outlined">
      <CardContent>
        <Typography
          variant="h5"
          component="h2"
          style={{
            textAlign: 'center',
            paddingBottom: '0px'
          }}
        >
          Acceso no permitido.
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Info
