import { useState } from 'react'
import { Container, Grid } from '@material-ui/core';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { dashboarScreenStyless } from './makeStyles/dashboardStyless';

type Props = {
  children: React.ReactChild
}

const DashboardScreen: React.FC<Props> = ({ children }) => {
  const { content, appBarSpacer, container } = dashboarScreenStyless();
  const [isNavOpen, setNavOpen] = useState(false);

  return (
    <div>
      <DashboardNavbar setNavOpen={setNavOpen}/>
      <DashboardSidebar
        onClose={setNavOpen}
        open={isNavOpen}
      />
      <main className={content}>
        <div className={appBarSpacer} />
        <Container maxWidth="xl" className={container}>
          <Grid container spacing={3}>
            { children }
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default DashboardScreen
